import React from 'react';
import Fade from 'react-reveal/Fade';
import BuisnessDomains from '../../../sections/softwareDevelopment/BuisnessDomains';
import buisness1 from '../../images/buisness1.jpg';
import webdev from '../../images/webdev.jpg';
import '../../Sass/softwareDev.scss';
const SoftwareDevelopment = () => {
  return (
    <div className="mainSoft">
      <h3 className="buisnss">IT Services</h3>
      <div className="container ">
        <div class="card-deck">
          <div class="card">
            <h3 class="card-title2">
              <Fade top>Web Development</Fade>
            </h3>

            <Fade left>
              <img class="card-img-top" src={webdev} alt="Card image cap" />
            </Fade>
            <div class="card-body">
              <Fade bottom>
                <p class="card-text ">
                  We hold unmatchable expertise in software development. K2ptech
                  work on variety of architectures, technologies, databases and
                  scales. Name any popular software technology and we can
                  program in it. If you are layman to software, we shall help
                  you picking up the right technology which can support your
                  business operations in the long run.
                </p>
              </Fade>
            </div>
          </div>
          <div class="card">
            <h3 class="card-title2">
              <Fade top>Mobile Development</Fade>
            </h3>
            <Fade right>
              <img
                class="card-img-top"
                src="https://miro.medium.com/max/1000/1*ZnVbmWK9nAothEg_de2fOA.jpeg"
                alt="Card image cap"
              />
            </Fade>
            <div class="card-body">
              <Fade bottom>
                <p class="card-text ">
                  One of the pioneers into mobile development. We provide 360
                  solutions around mobile – ranging from mobile strategy to
                  mobile marketing. We do more than just develop your product,
                  we help you refine your idea and execute it successfully. As a
                  company observing the development, retreat and re-development
                  of the I.T. world, we are positioned to spot invisible trends
                  even as they are just shaping up.This card has supporting text
                  below as a natural lead-in to additional content.
                </p>
              </Fade>
            </div>
          </div>
        </div>
        <div class="card-deck">
          <div class="card">
            <h3 class="card-title2">
              <Fade top>Business Intelligence</Fade>
            </h3>
            <Fade left>
              <img class="card-img-top" src={buisness1} alt="Card image cap" />
            </Fade>
            <div class="card-body">
              <Fade bottom>
                <p class="card-text ">
                  Solid network is basic need to run a solid base. Owing to most
                  of our experience of the U.S. market, we got a chance to work
                  on complicated systems and solved complex problems. Wide range
                  of our Network Services include: Server Management, Network
                  Monitoring, Network Security, E-mail Server Management,
                  Intrusion Prevention, Disaster Recovery and High Availability
                  Cluster Management.
                </p>
              </Fade>
            </div>
          </div>
          <div class="card">
            <h3 class="card-title2">
              <Fade top>Business Analysis</Fade>
            </h3>
            <Fade right>
              <img
                class="card-img-top"
                src="https://aeioutech.com/wp-content/uploads/2021/05/home-buisness-680x350.jpg"
                alt="Card image cap"
              />
            </Fade>
            <div class="card-body">
              <Fade bottom>
                <p class="card-text ">
                  Business Analysis is systematic approach to automate your
                  systems and helps you in your most important I.T. Projects. It
                  is ideal in a market like Pakistan where decision makers are
                  not I.T. literate. To do this, our Business Analyst visits
                  your office, goes through your system and related documents,
                  meets with in-house staff and maps out all required aspects.
                  This preliminary analysis is further refined and once
                  approved, sent to the software development team for price and
                  time estimation to automate your system.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <BuisnessDomains />
    </div>
  );
};

export default SoftwareDevelopment;
