import React from 'react';
import Fade from 'react-reveal/Fade';
import '../../components/css/aboutus.css';
const AboutGoals = () => {
  return (
    <div className="headerSection ">
      <section className="aboutSection container py-4">
        <div className="leftSection">
          <div className="heading">
            <Fade left>
              <h1>OUR GOALS?</h1>
            </Fade>
          </div>
          <div className="divider">
            <span className="divider-separator"></span>
          </div>
          <div className="content">
            <p>
              We work towards fueling companies across the world with the power
              of technology and software. Our tailored IT solutions and services
              are helping enterprises leverage technology to maximise their
              business potential.
            </p>
            <p>
              K2ptech have a simple goal to turn your great ideas into reality.
              Our pool of certified developers helps companies looking to build,
              grow or mature their technology implementations. Our remote
              developers strive hard to achieve clients satisfaction and exceed
              their expectations by delivering robust, scalable and highly
              flexible software solutions.
            </p>
          </div>
        </div>

        <div className="rightSection1 py-5">
          <Fade right>
            <img
              width="320"
              alt=""
              data-src="https://zeptosystems.com/wp-content/uploads/2019/07/icons.png"
              class="attachment-large size-large lazyloaded"
              src="https://zeptosystems.com/wp-content/uploads/2019/07/icons.png"
            />
          </Fade>
        </div>
      </section>
    </div>
  );
};

export default AboutGoals;
