import React from 'react';
import { FaFacebookF, FaPhoneAlt, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import '../css/social.css';
const SocialIcons = () => {
  return (
    <div className="socialIcons">
      <a
        className="icons"
        href="https://www.facebook.com/K2ptech-208319634547532"
        aria-label="facebook"
        target="_blank"
      >
        <FaFacebookF size="20px" />
      </a>
      <a
        className="icons"
        href="https://twitter.com/k2ptech"
        aria-label="twitter"
      >
        <FaTwitter size="20px" />
      </a>
      <a className="icons" href="mailto:k2ptech@gmail.com" aria-label="Email">
        <MdEmail size="20px" />
      </a>
      <a className="icons" href="tel:+923023143235" aria-label="Phone">
        <FaPhoneAlt size="20px" />
      </a>
    </div>
  );
};

export default SocialIcons;
