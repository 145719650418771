import React from 'react';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import { useHistory } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../.././components/css/intopage.css';
const IntroPage = () => {
  let history = useHistory();
  const redirect = (val) => {
    history.push(val);
  };
  return (
    <div>
      <Reveal>
        <div className="headerSection aboutUS">
          <div className="blueOuterBackground">
            <div className="blueInnerSection py-5">
              <div className="overlay"></div>
              <section className="aboutSection container py-4">
                <div className="blueLeftSection">
                  <div className="heading">
                    <Fade left>
                      <h1>
                        FULLY MANAGED IT PROJECT DEVELOPMENT AND PRODUCT SUPPORT
                        SERVICES
                      </h1>
                    </Fade>
                  </div>
                  <div className="divider">
                    <span className="divider-separator"></span>
                  </div>
                  <h4>How are we helping our customers ?</h4>
                  <div className="content">
                    <p>
                      K2ptech has been delivering cost-effective product
                      development and support services with our complete and
                      partial delivery management. We set up IT Teams best
                      fitting your business requirements both when you have
                      established in-house engagement capabilities or not yet
                      with complete SLDC and ITIL management responsibility.
                    </p>
                    <p>
                      We provide skilled services to meet your customized needs
                      including product, project , process and IT delivery
                      management also business analysis, development, quality
                      assurance services. This also includes cyber-security
                      management, DevOps and deployments, cloud infrastructure
                      setup, infrastructure migrations along with complete
                      infrastructure management, different lines of supports
                      with extension to 24X7 services. We have been providing
                      complete and subset of these services both at a fix and
                      variable costs promised with assured SLAs.
                    </p>
                  </div>

                  <button
                    className="myButton"
                    onClick={() => redirect('/hiring')}
                  >
                    Get a free route
                  </button>
                </div>
                <div className="blueRightSection"></div>
              </section>
            </div>
          </div>
        </div>
      </Reveal>
      <div className="headerSection  ">
        <section className="section1 container py-3">
          <div className="leftSection">
            <div className="heading">
              <Fade left>
                <h2>DO YOURSELF AND YOUR BUSINESS A FAVOUR NOW?</h2>
              </Fade>
            </div>
            <div className="divider">
              <span className="divider-separator"></span>
            </div>
            <div className="content">
              <p>
                Spare your time, energy and money. Hire a team that is capable,
                scalable and agile to your needs, friendly, strong in skills and
                drives success fast. For 12+ years we have shared success with
                our customers, removed their growth barriers, risks and stress.
                We can help you attain exponential fast growth, high retention
                rate and extensive cost savings with our IT services and
                experience in team building.
              </p>
            </div>
          </div>

          <div className="rightSection1 py-5">
            <Fade right>
              <img
                src="https://zeptosystems.com/wp-content/uploads/2019/07/img1-926x1024.png"
                width="280"
                alt="abc"
              ></img>
            </Fade>
          </div>
        </section>
      </div>
      {/* SECTION 2 */}

      <div className="headerSection  bg-light">
        <section className="section1 container py-5">
          <div className="rightSection2 py-5">
            <Fade left>
              <img
                width="273"
                height="261"
                alt=""
                data-src="https://zeptosystems.com/wp-content/uploads/2018/04/bussiness_ico.png"
                class="attachment-large size-large lazyloaded"
                src="https://zeptosystems.com/wp-content/uploads/2018/04/bussiness_ico.png"
              ></img>
            </Fade>
          </div>
          <div className="leftSection">
            <div className="heading">
              <Fade right>
                <h2>IT BRILLIANCE</h2>
              </Fade>
            </div>
            <div className="divider">
              <span className="divider-separator"></span>
            </div>
            <div className="content">
              <p>
                Integrate state-of-the-art technological solutions to your
                business with us. Decide what kind of help you require and we
                will tailor our IT solutions and teams around your needs. Our
                services range from IT consulting to fully managed and unmanaged
                IT assistance.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="headerSection ">
        <section className="section1 container py-5">
          <div className="leftSection">
            <div className="heading">
              <Fade left>
                <h2>TIME TESTED AND TRUSTED IT TEAM</h2>
              </Fade>
            </div>
            <div className="divider">
              <span className="divider-separator"></span>
            </div>
            <div className="content">
              <p>
                Get the industry best IT consulting as well as outsourcing
                services with our skilled resources stationed in house or
                deployed remote, readily available at our offshore offices and
                save up to 60% of the in-house costs. You can either outsource
                your IT project to our skilled staff or hire a single developer
                as an extension of your in-house IT team. Contact today and get
                started in less than 8 weeks!
              </p>
            </div>
            <span className="myRoute">
              <Fade bottom>
                <button
                  className="myButton"
                  onClick={() => redirect('/hiring')}
                >
                  Hire Your Own Team
                </button>
              </Fade>
            </span>
          </div>

          <div className="rightSection1 py-5">
            <Fade right>
              <img
                width="320"
                alt=""
                data-src="https://zeptosystems.com/wp-content/uploads/2019/07/icons.png"
                class="attachment-large size-large lazyloaded"
                src="https://zeptosystems.com/wp-content/uploads/2019/07/icons.png"
              />
            </Fade>
          </div>
        </section>
      </div>
    </div>
  );
};

export default IntroPage;
