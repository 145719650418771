import React from 'react';
import Fade from 'react-reveal/Fade';
import img from '../images/whatsapp-scale.gif';
const LiveChatt = () => {
  return (
    <Fade bottom>
      <div className="bottom-chatt">
        <a href="https://wa.me/+923023143235" target="_blank" rel="phone no">
          <img src={img} alt="messenger-icon"></img>
        </a>
      </div>
    </Fade>
  );
};

export default LiveChatt;
