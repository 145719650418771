import React from "react";
import Fade from "react-reveal/Fade";
import "../Sass/softwareDev.scss";
const Protfolio = () => {
  return (
    <>
      {" "}
      <div className="mainSoft">
        <h3 className="buisnss">Our Portfolio</h3>
        <div className="container ">
          <div class="card-deck">
            <div class="card">
              <h3 class="card-title2">
                <Fade top>EBS to Apex integration</Fade>
              </h3>

              <Fade left>
                <img
                  class="card-img-top"
                  src="https://1330878074.rsc.cdn77.org/wp-content/uploads/2022/05/Oracle-Enhances-its-Comprehensive-Cloud-Security-Capabilities-with-Integrated-Threat-Management.jpg"
                  alt="Card image cap"
                />
              </Fade>
              <div class="card-body">
                <Fade bottom>
                  <p class="card-text ">
                    EBS to Apex integration Oracle Apex based documents upload
                    and approval solution integrated with EBS.
                  </p>
                </Fade>
              </div>
            </div>
            <div class="card">
              <h3 class="card-title2">
                <Fade top>RIS and PACS solution</Fade>
              </h3>
              <Fade right>
                <img
                  class="card-img-top"
                  src="https://crc.losrios.edu/crc/main/img/page-assets/Body-Office-UniversalDetail-940x529/cac/business-and-computer-science-body.png"
                  alt="Card image cap"
                />
              </Fade>
              <div class="card-body">
                <Fade bottom>
                  <p class="card-text ">
                    RIS solution integrated with DCM4CHE based PACS solution via
                    Mirth Connect and dicom viewing solution using OHIF
                  </p>
                </Fade>
              </div>
            </div>
          </div>
          <div class="card-deck">
            <div class="card">
              <h3 class="card-title2">
                <Fade top>Tour Munk</Fade>
              </h3>
              <Fade left>
                <img
                  class="card-img-top"
                  src="https://www.dubaidesertsafaris.com/wp-content/uploads/2022/02/desert-safari-dubai.jpg"
                  alt="Card image cap"
                />
              </Fade>
              <div class="card-body">
                <Fade bottom>
                  <p class="card-text ">
                    Dubai Traveling website booking destinations, tickets and
                    rides.
                  </p>
                </Fade>
              </div>
            </div>
            <div class="card">
              <h3 class="card-title2">
                <Fade top>Forex Master</Fade>
              </h3>
              <Fade right>
                <img
                  class="card-img-top"
                  src="https://img.freepik.com/free-vector/forex-trading-background_52683-42357.jpg?w=2000"
                  alt="Card image cap"
                />
              </Fade>
              <div class="card-body">
                <Fade bottom>
                  <p class="card-text ">
                    Super-charting platform and social network for traders and
                    investors. Upgraded Plans & Extra Features.
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-2  buisnessDomain">
        <Fade bottom>
          <h3 className="buisnss">Technologies</h3>
        </Fade>
        <div class="card-deck2 px-4">
          <div class="card2 Tback1">
            <div class="transbox">
              <Fade left>
                <h5>React Js</h5>
              </Fade>
            </div>
          </div>
          <div class="card2 Tback2">
            <div class="transbox">
              <Fade left>
                <h5>React Native</h5>
              </Fade>
            </div>
          </div>
          <div class="card2 Tback3">
            <div class="transbox">
              <Fade left>
                <h5>Flutter</h5>
              </Fade>
            </div>
          </div>
          <div class="card2 Tback4">
            <div class="transbox"></div>
          </div>
          <div class="card2 Tback5">
            <div class="transbox"></div>
          </div>
          <div class="card2 Tback10">
            <div class="transbox">
              <h5>Oracle EBS</h5>
            </div>
          </div>{" "}
          <div class="card2 Tback10">
            <div class="transbox">
              <h5>Oracle APEX</h5>
            </div>
          </div>{" "}
          <div class="card2 Tback10">
            <div class="transbox">
              <b>
                <h5>B2B Integration</h5>
              </b>
            </div>
          </div>
        </div>
        <h3 className="buisnss">Middle Tier</h3>
        <div class="card-deck2 px-4">
          <div class="card2 Tback6">
            <div class="transbox"></div>
          </div>
          <div class="card2 Tback7">
            <div class="transbox"></div>
          </div>
          <div class="card2 Tback8">
            <div class="transbox"></div>
          </div>
          <div class="card2 Tback9">
            <div class="transbox">
              <Fade left>
                <h5>.NET MVC</h5>
              </Fade>
            </div>
          </div>
        </div>
        <h3 className="buisnss">Database</h3>
        <div class="card-deck2 px-4">
          <div class="card2 Tback10">
            <div class="transbox"></div>
          </div>

          <div class="card2 Tback12">
            <div class="transbox">
              <Fade left>
                <h5>SQL/MySQL</h5>
              </Fade>
            </div>
          </div>
          <div class="card2 Tback11">
            <div class="transbox">
              <Fade left>
                <h5>Mongodb</h5>
              </Fade>
            </div>
          </div>

          <div class="card2 Tback13">
            <div class="transbox">
              <Fade left>
                <h5>PostgreSQL</h5>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Protfolio;
