import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import IntroPage from '../../sections/Home/IntroPage';
import '../css/home.css';

const Home = () => {
  let history = useHistory();
  useEffect(() => {
    history.push('/home');
  }, []);
  return (
    <div className="mainHome">
      <IntroPage />
    </div>
  );
};

export default Home;
