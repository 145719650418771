import React from "react";
import Fade from "react-reveal/Fade";
import ahmad from "../../components/images/ahmad.jpg";
import faisal from "../../components/images/faisal.jpg";
import umer from "../../components/images/umer.jpg";
import Card from "./Card";
const Mobileteam = () => {
  return (
    <div className="teamCard py-3">
      <Fade right>
        <h3>Mobile Development</h3>
      </Fade>
      <div className="cardDivider">
        <span className="divider-separator"></span>
      </div>
      <div className="founderCard py-5">
        <div class="card-deck">
          <Card
            img={faisal}
            title="Faisal  Ur Rehman"
            text="Senior Flutter | React Native Developer"
          />
          <Card
            img={ahmad}
            title="Ahmad Badar"
            text="Senior Android Developer"
          />
          <Card img={umer} title="Umer Asif" text="Jr. Android/IOS Developer" />
        </div>
      </div>
    </div>
  );
};

export default Mobileteam;
