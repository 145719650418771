import './css/footer.css';
export default function Footer(props) {
  return (
    <div className="footerWave">
      <div className="footer">
        <p>Copyright © 2021 k2ptech , All Rights Reserved.</p>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#0099ff"
          fill-opacity="1"
          d="M0,32L48,48C96,64,192,96,288,101.3C384,107,480,85,576,101.3C672,117,768,171,864,160C960,149,1056,75,1152,53.3C1248,32,1344,64,1392,80L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
}
