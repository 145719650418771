import React from 'react';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import { useHistory } from 'react-router-dom';
import '../../components/css/aboutus.css';

const AboutusSection = () => {
  let history = useHistory();
  const redirect = (val) => {
    history.push(val);
  };
  return (
    <Reveal>
      <div className="headerSection aboutUS">
        <div className="outerBackground">
          <div className="innerSection py-5">
            <section className="aboutSection container py-4">
              <div className="leftSection">
                <div className="heading">
                  <Fade left>
                    <h1>WHO ARE WE?</h1>
                  </Fade>
                </div>
                <div className="divider">
                  <span className="divider-separator"></span>
                </div>
                <div className="content">
                  <p>
                    K2ptech is an Software development Company. K2ptech has been
                    providing 360 degree IT services to its clients; which
                    include but are not limited to: software development, web
                    development, mobile app development and infrastructure.
                  </p>
                  <p>
                    K2ptech has built quite a diversified work portfolio, from
                    working in the ecommerce industry to healthcare , banking &
                    finance sector – we have done it all.
                  </p>
                </div>
              </div>

              <div className="rightSection py-5">
                <Fade right>
                  <span className="bunRoute">
                    <button
                      className="myButton"
                      onClick={() => redirect('/hiring')}
                    >
                      Contact Us Now
                    </button>
                  </span>
                </Fade>
                <Fade right>
                  <span className="bunRoute">
                    <button
                      className="myButton"
                      onClick={() => redirect('/hiring')}
                    >
                      Speak to Us Buisness Development Team Now
                    </button>
                  </span>
                </Fade>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default AboutusSection;
