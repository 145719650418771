import React from "react";
import Fade from "react-reveal/Fade";
const Card = (props) => {
  return (
    <div class="card">
      <Fade left>
        <img
          class="card-img-top"
          src={props.img}
          alt={props.title}
          className="cardAvatar3"
        />
      </Fade>
      <div class="card-body">
        <Fade left>
          <h5 class="card-title">{props.title}</h5>
          <p class="card-text cardText2">{props.text}</p>
        </Fade>
      </div>
    </div>
  );
};

export default Card;
