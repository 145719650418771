import Fade from "react-reveal/Fade";
import { useEffect } from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./css/header.css";
import logo from "./images/Logo.gif";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const location = useLocation();
  useEffect(() => {
    console.log(location);
  }, [location]);
  return (
    <Fade top>
      <div className="container-fluid  sticky-top navbarHeader">
        <div className="row my-0">
          <div className="col-12 mx-auto my-2 inerHeader">
            <nav className="navbar navbar-expand-md  navbar-light ">
              <Link className="navbar-brand" to="#">
                <img src={logo} alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link
                      data-toggle="collapse"
                      data-target="#collapsibleNavbar"
                      className={
                        location.pathname === "/home"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/home"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      data-toggle="collapse"
                      data-target="#collapsibleNavbar"
                      className={
                        location.pathname === "/portfolio"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/portfolio"
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      data-toggle="collapse"
                      data-target="#collapsibleNavbar"
                      className={
                        location.pathname === "/team"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/team"
                    >
                      Team
                    </Link>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      className={
                        location.pathname === "/software/development" ||
                        location.pathname === "/consultancy"
                          ? "nav-link active dropdown-toggle"
                          : "nav-link dropdown-toggle"
                      }
                      id="navbardrop"
                      data-toggle="dropdown"
                    >
                      Services
                    </a>
                    <div class="dropdown-menu">
                      <Link
                        data-toggle="collapse"
                        data-target="#collapsibleNavbar"
                        class="dropdown-item"
                        to="/software/development"
                      >
                        Software Development
                      </Link>
                      <Link
                        data-toggle="collapse"
                        data-target="#collapsibleNavbar"
                        class="dropdown-item"
                        to="/consultancy"
                      >
                        IT Consultancy
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item about">
                    <Link
                      data-toggle="collapse"
                      data-target="#collapsibleNavbar"
                      className={
                        location.pathname === "/about-us"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/about-us"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      data-toggle="collapse"
                      data-target="#collapsibleNavbar"
                      className={
                        location.pathname === "/contact"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/contact"
                    >
                      Contact
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className={
                        location.pathname === "/portfolio"
                          ? "nav-link active"
                          : "nav-link"
                      } to="/courses">
                      Courses
                    </Link>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Header;
