import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import LiveChatt from "./components/buttonComponent/LiveChatt";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutUs from "./components/pages/AboutUs";
import Contact from "./components/pages/Contact";
import Courses from "./components/pages/Courses";

import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import PageNotFound from "./components/pages/PageNotFound";
import Protfolio from "./components/pages/Protfolio";
import ITConsultancy from "./components/pages/services/ITConsultancy";
import SoftwareDevelopment from "./components/pages/services/SoftwareDevelopment";
import Team from "./components/pages/Team";
import SocialIcons from "./components/social media/SocialIcons";

const App = () => {
  return (
    <div className="myApp">
      <Router>
        {/* <TopHeader /> */}
        <Header />
        <SocialIcons />
        <div className="mainBody">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/about-us">
              <AboutUs />
            </Route>

            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/portfolio">
              <Protfolio />
            </Route>

            <Route exact path="/team">
              <Team />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/courses">
              <Courses />
            </Route>
            <Route exact path="/software/development">
              <SoftwareDevelopment />
            </Route>

            <Route exact path="/consultancy">
              <ITConsultancy />
            </Route>

            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
      </Router>

      <LiveChatt />

      <Footer />
    </div>
  );
};
export default App;
