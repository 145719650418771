import React from "react";
import Fade from "react-reveal/Fade";
import cardImage from "../../components/images/tasleem.jpg";
import female from "../../components/images/female.jpg";
import naeem from "../../components/images/naeem.jpg";
const Founders = () => {
  return (
    <div className="teamCard teamMain mt-3">
      <Fade bottom>
        <h4 className="text-primary">
          𝙈𝙚𝙚𝙩 𝙤𝙪𝙧 𝙩𝙚𝙖𝙢 𝙤𝙛 𝙠2𝙥𝙩𝙚𝙘𝙝 𝙬𝙖𝙧𝙧𝙞𝙤𝙧𝙨, 𝙧𝙚𝙖𝙙𝙮 𝙩𝙤 𝙗𝙤𝙤𝙨𝙩 𝙮𝙤𝙪𝙧 𝙗𝙪𝙨𝙞𝙣𝙚𝙨𝙨.
        </h4>
      </Fade>

      <div className=" py-3 cofounderCard">
        <div class="card">
          <Fade left>
            <img
              class="card-img-top"
              src={cardImage}
              alt="Card image cap"
              className="cardAvatar"
            />
          </Fade>
          <div class="card-body">
            <Fade left>
              <h5 class="card-title">Muhammad Tasleem</h5>
              <p class="card-text cardText2">Co-Founder/Director</p>
            </Fade>
          </div>
          {/* <div class="card-footer">
              <small class="text-muted">Last updated 3 mins ago</small>
            </div> */}
        </div>

        <div class="card">
          <Fade right>
            <img
              class="card-img-top"
              src={naeem}
              alt="Card image cap"
              className="cardAvatar"
            />
          </Fade>
          <div class="card-body">
            <Fade right>
              <h5 class="card-title">Naeem Qasim</h5>
              <p class="card-text cardText2">
                CTO/ERP solutions and B2B Integration specialist
              </p>
            </Fade>
          </div>
          {/* <div class="card-footer">
              <small class="text-muted">Last updated 3 mins ago</small>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Founders;
