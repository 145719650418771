import React from 'react';
import Fade from 'react-reveal/Fade';
const BuisnessDomains = () => {
  return (
    <div className="container-fluid my-5 py-2 buisnessDomain">
      <Fade bottom>
        <h3 className="buisnss">Business Domains</h3>
      </Fade>
      <div class="card-deck2 px-4">
        <div class="card2 back1">
          <div class="transbox">
            <Fade left>
              <h5>Health care</h5>
            </Fade>
          </div>
        </div>
        <div class="card2 back2">
          <div class="transbox">
            <Fade left>
              <h5>Constructions</h5>
            </Fade>
          </div>
        </div>
        <div class="card2 back3">
          <div class="transbox">
            <Fade left>
              <h5>Pharmaceutical</h5>
            </Fade>
          </div>
        </div>
        <div class="card2 back4">
          <div class="transbox">
            <Fade left>
              <h5>Telecom </h5>
            </Fade>
          </div>
        </div>
        <div class="card2 back5">
          <div class="transbox">
            <Fade left>
              <h5>Ecommerce</h5>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuisnessDomains;
