import React from 'react';
import Designers from '../../sections/Teams/Designers';
import Founders from '../../sections/Teams/Founders';
import Mobileteam from '../../sections/Teams/Mobileteam';
import Webteam from '../../sections/Teams/Webteam';
import '../Sass/card.scss';
const Team = () => {
  return (
    <div className="container-fluid mainTeam">
      {/* <WorkProgress /> */}
      <Founders />
      <Webteam />
      <Mobileteam />
      <Designers />
    </div>
  );
};

export default Team;
