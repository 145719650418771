import { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail, MdLocationOn, MdPerson } from "react-icons/md";
import "../Sass/contact.scss";

const Contact = () => {
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [chek, setChek] = useState(false);
  const SubmitForm = (event) => {
    setChek(true);
    setName2(name);
    setName("");
    setEmail("");
    setText("");
  };
  return (
    <div className="container-fluid rounded contact">
      {chek ? <h3 className="">Thanks {name2} to contact us</h3> : null}

      <div className="row mx-auto">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div>
            <h3 className="">Get in touch with k2ptech</h3>
            <p className="text-secondary">
              Fill up the form and our Team will get back to you within in 24
              hours
            </p>
            <a href="tel:+92302 3143235" className="btn rounded  p-3">
              <FaPhoneAlt className="mx-2" />
              +92 302 3143235
            </a>

            <a href="mailto:k2ptech@gmail.com" className="btn rounded  p-3">
              <MdEmail className="mx-2" />
              k2ptech@gmail.com
            </a>
            <a
              href="https://goo.gl/maps/hEe4MKxB6P7wmzD26"
              className="btn rounded  p-3"
            >
              <MdLocationOn className="mx-2" />
              427, L Block Khayaban-e-Amin, Lahore, Punjab 56300
            </a>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 pad">
          <form className="rounded msg-form" onSubmit={FormData}>
            <div className="form-group">
              <label for="name" className="h6">
                Your Name
              </label>
              <div className="input-group border rounded">
                <div className="input-group-addon px-2 pt-1">
                  <MdPerson />
                </div>
                <input
                  type="text"
                  value={name}
                  className="form-control border-0"
                  placeholder="User Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="name" className="h6">
                Email
              </label>
              <div className="input-group border rounded">
                <div className="input-group-addon px-2 pt-1">
                  <MdEmail />
                </div>
                <input
                  type="text"
                  value={email}
                  className="form-control border-0"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="msg" className="h6">
                Message
              </label>
              <textarea
                name="message"
                id="msgus"
                value={text}
                cols="10"
                rows="5"
                className="form-control bg-light"
                placeholder="Message"
                onChange={(e) => setText(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group d-flex justify-content-end">
              <a
                href={
                  "https://wa.me/+92302 3143235?text=Hello%20K2p%20Teck!_%0aI%20am%20*" +
                  name +
                  "*.%0aEmail%20:%20" +
                  email +
                  "%0aMessage%20:%20" +
                  text
                }
                className="btn btn-success mt-3"
                target="_blank"
                rel="noopener noreferrer"
                title="Send Message"
              >
                Send Message
                <i className="fa fa-whatsapp ml-2"></i>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
