import React from 'react';
import image from '../images/work in progress.png';
const WorkProgress = () => {
  return (
    <div className="workProgress">
      <img src={image} alt="work in progress"></img>
    </div>
  );
};

export default WorkProgress;
