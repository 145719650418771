import React from 'react';
import { MdLocalLibrary, MdPermContactCalendar, MdSpa } from 'react-icons/md';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import { useHistory } from 'react-router-dom';

const Whyk2ptech = () => {
  let history = useHistory();
  const redirect = (val) => {
    history.push(val);
  };
  return (
    <>
      <div className="headerSection K2ptechBack py-3 ">
        <section className="aboutWhySection container py-4">
          <div className="leftSectionK2p">
            <div className="heading">
              <Fade left>
                <h1>WHY K2PTECH?</h1>
              </Fade>
            </div>
            <div className="divider">
              <span className="divider-separator"></span>
            </div>
            <div className="content">
              <p>
                We help the business grow by providing solutions fitting their
                business needs.
              </p>
              <Fade Bottom>
                <button
                  className="myButton"
                  onClick={() => redirect('/hiring')}
                >
                  get in touch
                </button>
              </Fade>
            </div>
          </div>

          <div className="rightSectionList  py-5">
            <Fade right>
              <ul>
                <li>
                  We are professionals in IT market with a decade of experience
                </li>
                <li>
                  We offer you flexible business models namely, IT Consulting,
                  Managed Services and Remote Resources
                </li>
                <li>
                  We understand the market trends and can advise you the best
                  strategies for business growth
                </li>
                <li>
                  We have a proven record of successful project deliveries
                </li>
                <li>
                  We fully abide by standard industry guidelines and compliance
                  systems
                </li>
              </ul>
            </Fade>
          </div>
        </section>
      </div>
      <div className="headerSection whyK2ptech">
        <div className="staticBackground">
          <div className="backInnerSection py-5">
            <div className="overlay"></div>
            <section className="aboutSectionCard container py-4">
              <Fade left>
                <div className="myCard">
                  <MdLocalLibrary
                    color="#ff248a"
                    style={{ fontSize: 40 }}
                    className="cardIcon"
                  />
                  <div className="heading">
                    <h4>HOLISTIC APPROACH TO DEVELOPMENT</h4>
                  </div>
                  <div className="divider">
                    <span className="divider-separator"></span>
                  </div>
                  <div className="content">
                    <p>
                      We take care of every aspect of the process while
                      developing a project or delivering complete strategic
                      services. What makes us stand out from other IT consulting
                      companies? No matter what service model you choose, our
                      solutions would be fully tailored to your specific needs
                      without any hardliners. In fact, our easily scalable teams
                      and flexible terms and conditions are the reason of our
                      excellent client retention.
                    </p>
                  </div>
                </div>
              </Fade>
              <Pulse>
                <div className="myCard">
                  <MdSpa
                    color="#ff248a"
                    style={{ fontSize: 40 }}
                    className="cardIcon"
                  />
                  <div className="heading">
                    <h4>BESPOKE SOLUTIONS</h4>
                  </div>
                  <div className="divider">
                    <span className="divider-separator"></span>
                  </div>
                  <div className="content">
                    <p>
                      K2ptech provides custom software development based on your
                      technology ambitions. Our solution architects can devise
                      an excellent software strategy for your business with
                      creative work flows and a detailed analysis of your
                      particular business needs. We are entrusted by many
                      businesses to enhance and extend their systems to reach
                      their full-potential.
                    </p>
                  </div>
                </div>
              </Pulse>
              <Fade right>
                <div className="myCard">
                  <MdPermContactCalendar
                    color="#ff248a"
                    style={{ fontSize: 40 }}
                    className="cardIcon"
                  />
                  <div className="heading">
                    <h4>DEDICATED SOFTWARE PROGRAMMERS</h4>
                  </div>
                  <div className="divider">
                    <span className="divider-separator"></span>
                  </div>
                  <div className="content">
                    <p>
                      We are an internationally renowned IT consulting company
                      with a range of certified web and mobile application
                      developers. Our software development services have
                      exceeded client expectations due to our continuous
                      dedication to work and enthusiasm to prove our skills. By
                      hiring our certified software developers you get full
                      control of your dedicated resources working solely on your
                      project.
                    </p>
                  </div>
                </div>
              </Fade>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whyk2ptech;
