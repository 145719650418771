import Fade from "react-reveal/Fade";
import azeem from "../../components/images/azeem ur rehman.jpg";
import fahad from "../../components/images/fahad.jpg";
import husnain from "../../components/images/husnain.jpg";
import junaid from "../../components/images/junaid.jpg";
import tayyab from "../../components/images/tayyab.jpg";
import Card from "./Card";
const Webteam = () => {
  return (
    <div className="teamCard py-3">
      <Fade right>
        <h3>Website Development</h3>
      </Fade>
      <div className="cardDivider">
        <span className="divider-separator"></span>
      </div>
      <div className="founderCard py-5">
        <div class="card-deck">
          <Card
            img={azeem}
            title="Azeem ur Rehman"
            text="Senior MernStack Developer"
          />
          <Card
            img="https://www.shareicon.net/data/512x512/2016/07/26/802043_man_512x512.png"
            title="Muhammad Bilal Khan"
            text="Business development manager/Oracle APEX consultant"
          />

          <Card
            img={husnain}
            title="Husnain Tassaduq"
            text="FullStack Web Developer"
          />
        </div>
      </div>

      <div className="founderCard ">
        <div class="card-deck">
          <Card
            img={junaid}
            title="Muhammad Junaid"
            text="Senior Web Developer"
          />

          <Card
            img={fahad}
            title="Fahad Dar"
            text="Senior Frontend Developer"
          />
          <Card
            img={tayyab}
            title="Tayyab Azeem"
            text="Jr. Frontend Developer"
          />
        </div>
      </div>
    </div>
  );
};

export default Webteam;
