import React from 'react';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import { useHistory } from 'react-router-dom';
import '../../css/services.css';

const ITConsultancy = () => {
  let history = useHistory();
  const redirect = (val) => {
    history.push(val);
  };
  return (
    <>
      <Reveal>
        <div className="headerSection aboutUS">
          <div className="blueOuterBackground">
            <div className="blueInnerSection py-5">
              <div className="overlay"></div>
              <section className="aboutSection container py-4">
                <div className="blueLeftSection">
                  <div className="heading">
                    <Fade left>
                      <h1>
                        FULLY MANAGED IT PROJECT DEVELOPMENT AND PRODUCT SUPPORT
                        SERVICES
                      </h1>
                    </Fade>
                  </div>
                  <div className="divider">
                    <span className="divider-separator"></span>
                  </div>
                  <h4>How are we helping our customers ?</h4>
                  <div className="content">
                    <p>
                      K2ptech has been delivering cost-effective product
                      development and support services with our complete and
                      partial delivery management. We set up IT Teams best
                      fitting your business requirements both when you have
                      established in-house engagement capabilities or not yet
                      with complete SLDC and ITIL management responsibility.
                    </p>
                    <p>
                      We provide skilled services to meet your customized needs
                      including product, project , process and IT delivery
                      management also business analysis, development, quality
                      assurance services. This also includes cyber-security
                      management, DevOps and deployments, cloud infrastructure
                      setup, infrastructure migrations along with complete
                      infrastructure management, different lines of supports
                      with extension to 24X7 services. We have been providing
                      complete and subset of these services both at a fix and
                      variable costs promised with assured SLAs.
                    </p>
                  </div>

                  <button
                    className="myButton"
                    onClick={() => redirect('/hiring')}
                  >
                    Get a free route
                  </button>
                </div>
                <div className="blueRightSection"></div>
              </section>
            </div>
          </div>
        </div>
      </Reveal>

      <div className="headerSection whyK2ptech">
        <div className="itStaticBackground">
          <div className="itbackInnerSection py-5">
            <div className="overlay"></div>
            <section className="section1 container py-5">
              <div className="leftSection itSection">
                <div className="heading">
                  <Fade left>
                    <h2>FULLY MANAGED PROJECT DELIVERY</h2>
                  </Fade>
                </div>
                <div className="divider">
                  <span className="divider-separator"></span>
                </div>
                <div className="content">
                  <p>
                    We are helping customers like you with our fully managed
                    development services. This mean that we will take care of
                    the complete management including delivery responsibility
                    spanning complete software development life-cycle. we take
                    care of business analysis , definition, development, quality
                    assurance , DevOps and configuration management. we would
                    work under your business deadlines and deliver to your
                    expectations on the fixed or variable cost on assured time
                    scale.
                  </p>
                </div>
                <button
                  className="myButton"
                  onClick={() => redirect('/hiring')}
                >
                  get in touch
                </button>
              </div>

              <div className="rightSection1 py-5">
                <Fade right>
                  <img
                    width="350"
                    alt=""
                    src="https://zeptosystems.com/wp-content/uploads/2018/04/tabs_peral-1024x734.png"
                  />
                </Fade>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ITConsultancy;
