import React from "react";
import Fade from "react-reveal/Fade";
import awais from "../../components/images/awais danish.jpg";
import jabir from "../../components/images/jabir.jpg";
const Designers = () => {
  return (
    <div className="teamCard py-3">
      <Fade right>
        <h3>UI/UX Designers</h3>
      </Fade>
      <div className="cardDivider">
        <span className="divider-separator"></span>
      </div>
      <div className="founderCard py-5">
        <div class="card">
          <Fade left>
            <img
              class="card-img-top"
              src={awais}
              alt="Card image cap"
              className="cardAvatar"
            />
          </Fade>
          <div class="card-body">
            <Fade left>
              <h5 class="card-title">Awais Danish</h5>
              <p class="card-text cardText2">UI/UX & Social Media Expert</p>
            </Fade>
          </div>
          {/* <div class="card-footer">
              <small class="text-muted">Last updated 3 mins ago</small>
            </div> */}
        </div>
        <div class="card">
          <Fade left>
            <img
              class="card-img-top"
              src={jabir}
              alt="Card image cap"
              className="cardAvatar"
            />
          </Fade>
          <div class="card-body">
            <Fade left>
              <h5 class="card-title">Malik Jabir</h5>
              <p class="card-text cardText2">Senior UI/UX Designer</p>
            </Fade>
          </div>
          {/* <div class="card-footer">
              <small class="text-muted">Last updated 3 mins ago</small>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Designers;
