import React from 'react';
import AboutGoals from '../../sections/aboutus/AboutGoals';
import AboutusSection from '../../sections/aboutus/AboutusSection';
import Whyk2ptech from '../../sections/aboutus/Whyk2ptech';
const AboutUs = () => {
  return (
    <div className="aboutUs">
      <AboutusSection />
      <AboutGoals />
      <Whyk2ptech />
    </div>
  );
};

export default AboutUs;
