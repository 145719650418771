import React from 'react';
import image from '../images/notfound.PNG';
const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <img src={image} alt="page not found"></img>
    </div>
  );
};

export default PageNotFound;
